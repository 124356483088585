import { MutationTree } from 'vuex';
import { DatasetState, DensityType } from './types';

export const mutations: MutationTree<DatasetState> = {
    datasetsLoaded(state, datasets: string[]) {
        state.datasetList = datasets;
        state.dataset = datasets[0];
    },
    setDataset(state, dataset: string) {
        state.dataset = dataset;
    },
    setDatasetType(state, type: DensityType) {
        state.type = type;
    },
    setNav(state, nav: string) {
        state.nav = nav;
    },
    setMetadata(state, metadata: any) {
        state.metadata = metadata;
    },
};
