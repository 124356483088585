export interface DatasetState {
    datasetList?: string[];
    dataset?: string;
    type: DensityType;
    nav: string;
    metadata: any;
    endpoint_type?: 'web' | 'bm';
}

export enum DensityType {
    Road = "Road",
    Rail = "Rail",
    Sea = "Sea",
    Air = "Air",
    Road_Events_Baseline = "Road_Events_Baseline",
    Road_Events_Scenario = "Road_Events_Scenario",
}
