

class Endpoints {


    get verifyRecaptchaUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/verify-recaptcha/";
    }
    get registerUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/register/";
    }
    get verifyRegistrationUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/verify-registration/";
    }
    get resetPasswordLinkUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/send-reset-password-link/";
    }
    get resetPasswordUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/reset-password/";
    }
    get profileUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/profile/";
    }
    get changePasswordUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/accounts/change-password/";
    }
    // Terms endpoint
    get termsUrl() {
        return process.env.VUE_APP_BACKEND_BASE + '/accounts/terms/';
    }
    get userUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/user";
    }
    get authUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/login/";
        //return process.env.VUE_APP_BACKEND_BASE + "/auth/"
    }
    get authRefreshTokenUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/token/refresh/";
    }
    get authCodeUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/login/code/";
    }
    get authRequestCodeUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/code/request/";
    }
    authActivateUrl(method_name: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/" + method_name + "/activate/";
    }
    authActivateConfirmUrl(method_name: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/" + method_name + "/activate/confirm/";
    }
    authDeactivateUrl(method_name: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/" + method_name + "/deactivate/";
    }
    get authChangePrimaryMethod() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/mfa/change-primary-method/";
    }
    get authConfigUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/mfa/config/";
    }
    get authUserActiveMethodsUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/mfa/user-active-methods/";
    }
    get authLogoutUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/auth/logout/";
    }

    // Registered datasets endpoint
    get datasetsUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/datasets";
    }
    //Baseline metadata endpoints
    metadataUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/datasets/" + dataset + "/metadata";
    }
    metadataBMUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/metadata?dataset=" + dataset;
    }

    //Filter values endpoints
    roadnamesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/roadnames/";
    }
    railnamesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/railnames/";
    }
    sealanesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/sealanes/";
    }
    gaugesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/gauges/";
    }
    commoditiesUrl(dataset: string, density_type: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/" + density_type + "/commodities/";
    }
    commod_l2Url(dataset: string, density_type: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/" + density_type + "/commodities_l2/";
    }
    commod_l3Url(dataset: string, density_type: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/" + density_type + "/commodities_l3/";
    }
    boundariesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/boundaries/";
    }
    enterprisesUrl(dataset: string, density_type: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/" + density_type + "/enterprises/";
    }
    enterpriseCategoriesUrl(dataset: string, density_type: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/" + density_type + "/enterprisecategories/";
    }
    accessTypesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/accesstypes/";
    }
    surfaceTypesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/surfacetypes/";
    }
    monthsUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/filters/" + dataset + "/months/";

    }

    // Assistant endpoint
    get assistantUrl() {
        return process.env.VUE_APP_BACKEND_BASE + '/assistant/ask_question';
    }
    get assistantSpeekUrl() {
        return process.env.VUE_APP_BACKEND_BASE + '/assistant/speek';
    }

    // Map tile related endpoints
    //Enterprises
    enterprisesTileUrlTemplate({dataset, densityType}: {dataset: string, densityType: string}) {
        return process.env.VUE_APP_BACKEND_BASE + "/tiles/" + dataset + "/" + densityType.toLowerCase() + '/baseline-enterprises/{z}/{x}/{y}.mvt';
    }
    eventsBaselineEnterprisesTileUrlTemplate({ dataset, densityType }: { dataset: string, densityType: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + "/" + densityType.toLowerCase() + '/event-enterprises/baseline/{z}/{x}/{y}.mvt';
    }
    eventsBlockedEnterprisesTileUrlTemplate({ dataset, densityType }: { dataset: string, densityType: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + "/" + densityType.toLowerCase() + '/event-enterprises/blocked/{z}/{x}/{y}.mvt';
    }
    eventsBlockedEnterprisesODTileUrlTemplate({ dataset, densityType }: { dataset: string, densityType: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + "/" + densityType.toLowerCase() + '/event-enterprises/od/{z}/{x}/{y}.mvt';
    }

    //Links
    roadsTileUrlTemplate({ dataset }: { dataset: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/road/baseline-links/{z}/{x}/{y}.mvt';
    }
    railTileUrlTemplate({ dataset }: { dataset: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/rail/baseline-links/{z}/{x}/{y}.mvt';
    }
    seaTileUrlTemplate({ dataset }: { dataset: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/sea/baseline-links/{z}/{x}/{y}.mvt';
    }
    roadBaselineTileUrlTemplate({ dataset, date }: { dataset: string, date: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/road_events_baseline/event-links/road_events/{z}/{x}/{y}.mvt';
    }
    roadScenarioTileUrlTemplate({ dataset, date }: { dataset: string, date: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/road_events_scenario/event-links/road_events/{z}/{x}/{y}.mvt';
    }
    eventsDeltaCommodTileUrlTemplate({ dataset, date }: { dataset: string, date: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/road_scenario/event-links/deltatrailers/{z}/{x}/{y}.mvt';
    }
    eventsClosedRoadsTileUrlTemplate({ dataset, date }: { dataset: string, date: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/road_scenario/event-links/closedroads/{z}/{x}/{y}.mvt';
    }
    

    // Cached DB links
    cachedTileUrl(dataset: string, densityType: string) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/' + densityType.toLowerCase() + '/db-cache-gen';
    }

    // Markers and closure categories
    eventsBlockedEnterprisesImpactedByTileUrlTemplate({ dataset, ent_id }: { dataset: string, ent_id: string | undefined }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/' + ent_id + '/impactedby/{z}/{x}/{y}.mvt';
    }
    eventsClosureCategoriesTileUrlTemplate({ dataset, date }: { dataset: string, date: string }) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/closurecategories/{z}/{x}/{y}.mvt';
    }

    // Boundaries
    boundariesTileUrlTemplate(dataset: string ) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/boundaries/{z}/{x}/{y}.mvt';
    }

    // Scenario related endpoints
    scenarioBoundsUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + '/scenarios/bounds/' + dataset;
    }
    viewScenarioReport(report_name: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/scenarios/scenario_report/" + report_name;
    }
    scenarioActionsLayerUrl(dataset: string, feattype: string) {
        return process.env.VUE_APP_BACKEND_BASE + '/tiles/' + dataset + '/scenario/' + feattype + '/{z}/{x}/{y}.mvt';
    }

    // Jobs related endpoints
    createTaskUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + '/tasks/create/' + dataset;
    }
    listTasksUrl() {
        return process.env.VUE_APP_BACKEND_BASE + '/tasks/list';
    }
    cancelTaskUrl(execution_id: string) {
        return process.env.VUE_APP_BACKEND_BASE + '/tasks/cancel/' + execution_id;
    }

    // Info panel related endpoints
    linkStatsUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/linkstats";
    }
    linkStatsByCommodityUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbycommodity";
    }
    linkStatsByIndustryUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbyindustry";
    }
    linkStatsByMonthUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbymonth";
    }
    linkDirectionUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkdirection";
    }
    linkCostUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkcost";
    }
    linkProductivityUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbyproductivity";
    }
    linkStatsByDestTypeUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbydesttype";
    }
    linkStatsByOrigTypeUrl(dataset: string, densityType: string, link_id: number) {
        return process.env.VUE_APP_BACKEND_BASE + "/linkinfo/" + dataset + "/" + densityType.toLowerCase() + "/" + link_id + "/reports/linkstatsbyorigtype";
    }


    // Events related endpoints
    eventDatesUrl(dataset: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/events/" + dataset + "/dates/";
    }
    impactReportUrl(date: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/events/impact_report/" + date;
    }
    // Context Reports related endpoints
    contextReportUrl(month: string, lga: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/events/context_report/" + month + "/" + lga;
    }


    // Table related endpoints
    movementsReportUrl(dataset: string, densityType: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/tables/" + dataset + "/" + densityType.toLowerCase() + "/movementreport";
    }
    linkReportUrl(dataset: string, densityType: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/tables/" + dataset + "/" + densityType.toLowerCase() + "/linkreport";
    }
    commodityReportUrl(dataset: string, densityType: string) {
        return process.env.VUE_APP_BACKEND_BASE + "/tables/" + dataset + "/" + densityType.toLowerCase() + "/commodityreport";
    }

}

export const endpoints: Endpoints = new Endpoints;
