import { GetterTree } from 'vuex';
import { EventsState } from './types';
import { RootState } from '../types';
import { isNullOrUndefined } from 'util';
import { DensityType } from '../datasets/types';


export const getters: GetterTree<EventsState, RootState> = {

    isInspectingBlockedEnterprise(state): boolean {
        return !(isNullOrUndefined(state.blocked_enterprise_id));
    },

    eventDensityType(state, getters, rootstate): DensityType {
        var report_type = (rootstate as any)['events'].report_type;
        var report_on = (rootstate as any)['events'].report_on;

        if (report_type == 'Event' || report_on == 'delta_trailers') {
            return DensityType.Road_Events_Scenario
        } else {
            return DensityType.Road_Events_Baseline
        }
    },

    hasEventDates(state, getters, rootstate): boolean {
        // Added a fallback to make sure that event_date_items is always an array - this is a potential fix for the 'blue screen'.
        // When the 'blue screen' happened last time, the error was: "Cannot read properties of undefined (reading 'length')" in hasEventDates method.
        // The 'blue screen' error can be reproduced by making event_date_items = undefined
        //return state.event_date_items.length > 0;
        return (state.event_date_items || []).length > 0; 
    },

};
