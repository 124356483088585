import { ActionTree } from 'vuex';
import axios from 'axios';
import { DatasetState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'
import buildUrl from 'build-url';


export const actions: ActionTree<DatasetState, RootState> = {
    getDatasets({ commit }): any {
        axios({
            url: endpoints.datasetsUrl
        }).then((response) => {
            var datasets = [];
            for (var i = 0; i < response.data.length; i++) {
                datasets.push(response.data[i].ID);
            }

            commit('datasetsLoaded', datasets);
        }, (error) => {
            console.log(error);
        });
    },
    setDataset({ commit }, dataset: string): any {
        commit('setDataset', dataset);
    },
    setDatasetType({ commit }, datasetType: string): any {
        commit('setDatasetType', datasetType);
    },
    setNav({ commit }, nav: string): any {
        commit('setNav', nav);
    },

    getMetadata({ commit }, { dataset, endpoint_type }: { dataset: string; endpoint_type: 'web' | 'bm' }): any {
        var endpoint = endpoint_type === 'web' ? endpoints.metadataUrl(dataset) : endpoints.metadataBMUrl(dataset);
        return new Promise((resolve, reject) => {
            axios({
                url: buildUrl(endpoint)
            }).then((response) => {
                var metadata = response.data;
                commit('setMetadata', metadata);
                resolve(metadata);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    }
};
